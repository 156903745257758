import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Apex Auto Enclave
			</title>
			<meta name={"description"} content={"Відкрийте для себе Apex Auto Enclave, найкраще місце для любителів екзотики та тюнінгу автомобілів"} />
			<meta property={"og:title"} content={"Apex Auto Enclave"} />
			<meta property={"og:description"} content={"Відкрийте для себе Apex Auto Enclave, найкраще місце для любителів екзотики та тюнінгу автомобілів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				Apex Auto Enclave
			</Override>
		</Components.Header>
		<Section padding="100px 0 100px 0" background="--color-dark" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="--headline1" color="--light">
				Поширені запитання
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						<Strong>
							1. Що таке Apex Auto Enclave?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Apex Auto Enclave – це ексклюзивний клуб для власників екзотичних автомобілів, який пропонує своїм членам доступ до спеціальних заходів, знижок, сервісних послуг та багато іншого.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						<Strong>
							2. Як стати членом клубу?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Для того, щоб стати членом Apex Auto Enclave, необхідно подати заявку через наш вебсайт. Заявка буде розглянута, і ми зв'яжемося з вами для подальших інструкцій.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						<Strong>
							3. Які переваги надає членство в клубі?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Члени клубу отримують доступ до ексклюзивних заходів, таких як автопробіги, виставки та майстер-класи. Також ми надаємо знижки на обслуговування авто, спеціальні умови страхування та доступ до партнерських програм.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						<Strong>
							4. Які типи автомобілів допускаються до клубу?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Ми приймаємо власників екзотичних автомобілів, таких як Ferrari, Lamborghini, Aston Martin, McLaren та інші висококласні автомобілі.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						<Strong>
							5. Чи є щорічний внесок за членство?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Так, для підтримки членства у клубі необхідно сплачувати щорічний внесок. Деталі щодо вартості можна знайти на нашому вебсайті або дізнатися у адміністрації клубу.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						<Strong>
							6. Чи можуть члени клубу запрошувати гостей на заходи?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Так, члени клубу можуть запрошувати гостей на деякі заходи. Для цього необхідно заздалегідь повідомити адміністрацію клубу та отримати підтвердження.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						<Strong>
							7. Які заходи організовує клуб?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Ми організовуємо різноманітні заходи, включаючи автопробіги, виставки, вечірки, майстер-класи з водіння та технічного обслуговування, а також зустрічі з відомими автоекспертами.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							8. Чи надає клуб сервісні послуги для автомобілів?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Так, ми надаємо сервісні послуги для автомобілів наших членів, включаючи технічне обслуговування, діагностику та ремонт. Для отримання детальної інформації, будь ласка, зв'яжіться з нашим сервісним центром.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});